<template>
  <b-card-code
    title="Project List"
  >  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-card-text>
      <span>Combine project powerpoint. You can arrange result file by drag & drop the project row </span>
    </b-card-text>

    <!-- draggable -->
    <draggable
      v-model="list"
      class="list-group list-group-flush cursor-move"
      tag="ul"
    >
      <transition-group
        type="transition"
        name="flip-list"
      >
        <b-list-group-item
          v-for="listItem in list"
          :key="listItem.projectId"
          tag="li"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex ">
              <!-- <b-avatar :text="listItem.projectName.slice(0,2)" /> -->
              <b-avatar :src="$webApi+'/coverPicturePath/'+listItem.coverPicture " />

              <div class="ml-25">
                <b-card-text class="font-weight-bold mb-0">
                  {{ listItem.projectName }}
                </b-card-text>
                <small>{{ listItem.customerName }}</small>
              </div>
            </div>
            <div class="ml-25">
              <!-- <b-card-text class="font-weight-bold mb-0">
                Delete
              </b-card-text> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="danger"
                @click="deleteItem(listItem.projectId)"
              >
                Delete
              </b-button>
            </div>
          </div>

        </b-list-group-item>
      </transition-group>
    </draggable>
    <br>
    <!-- search input -->

    <div class="demo-inline-spacing justify-content-between">
      <div class="demo-inline-spacing">
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="success"
            @click="merge"
          >
            <b-spinner
              v-show="loading"
              small
            />
            Merge
          </b-button>
        </b-form-group>
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="danger"
            @click="deleteAll"
          >
            Delete all
          </b-button>
        </b-form-group>
      </div>
      <b-form-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="secondary"
          @click="gotoProject"
        >
          Back
        </b-button>
      </b-form-group>
    </div>

    <!-- toggleable code-->
    <template #code>
      {{ codeAnimation }}
    </template>
  </b-overlay>
  </b-card-code>
</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Ripple from 'vue-ripple-directive'
import {
  BAvatar, BListGroupItem, BCardText, BButton, BFormGroup, BSpinner, BOverlay,
} from 'bootstrap-vue'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'

import { codeBasicGoodTable, codeAnimation } from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

export default {
  components: {
    BCardCode,
    BAvatar,
    draggable,
    BListGroupItem,
    BCardText,
    BButton,
    BFormGroup,
    BSpinner,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      codeAnimation,
      pageLength: 3,
      dir: false,
      codeBasicGoodTable,
      list: [],
      loading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    useJwt.getProjectDetails(JSON.parse(localStorage.getItem('items')))
      .then(res => {
        this.list = res.data.details
      })
  },
  methods: {

    gotoProject() {
      this.$router.push({ name: 'projects' })
    },
    deleteItem(projectId) {
      console.log(projectId)
      this.list.splice(this.list.findIndex(item => item.projectId === projectId), 1)
      const items = JSON.parse(localStorage.getItem('items'))
      items.splice(items.findIndex(item => item.projectId === projectId), 1)
      localStorage.setItem('items', JSON.stringify(items))
    },
    deleteAll() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          localStorage.setItem('items', '')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Your project list has been deleted.',
              icon: 'Trash2Icon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'projects' })
        }
      })
    },
    merge() {
      this.loading = true
      // console.log(this.list)
      const projectIds = this.list.map(el => el.projectId)

      useJwt.merge(projectIds)
        .then(res => {
          if (res.data.message != null) {
            this.loading = false
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              html: `Something went wrong.<br> (${res.data.message})`,
            })
            return
          }
          console.log(res.data.fileName)
          useJwt.getMergedFile(res.data.fileName)
            .then(response => {
              this.loading = false
              const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
              const fileLink = document.createElement('a')
              const contentDisposition = response.headers['content-disposition']
              console.log(contentDisposition)

              const match = contentDisposition.match(/filename\s*=\s*"?(.+)"?;/)
              const filename = match[1]
              fileLink.href = fileURL
              fileLink.setAttribute('download', filename)
              document.body.appendChild(fileLink)

              fileLink.click()
            }).then(() => {
              this.loading = false
              localStorage.setItem('items', '')
              this.$router.push({ name: 'projects' })
            })
        }).catch(error => {
          this.loading = false
          if (typeof error.response.data.error !== 'undefined') {
            console.log(error.response.data.error)
            this.$swal({
              icon: 'error',
              title: 'File not found',
              html: `Powerpoint of this project does not exists  : ${error.response.data.error.join(',<br>')}`,
            })
          }
        })
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
